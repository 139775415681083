const el = document.getElementById("direction");

el.addEventListener("click", () => {
  const el2 = document.getElementById("directionCard");
  const el3 = document.getElementById("passDes");
  if (el.innerText === "出") {
    el.innerText = "入";
    el2.innerText = "进";
    el3.innerText = "进入校区";
  } else {
    el.innerText = "出";
    el2.innerText = "出";
    el3.innerText = "离开校区";
  }
});

function pad2(num) {
  return (num + "").padStart(2, "0");
}

const scanT = document.getElementById("scan");
scanT.addEventListener("click", () => {
  const timeEl = document.getElementById("scanTime");
  const date = new Date();
  timeEl.innerText = `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(
    date.getDate()
  )} ${date.toLocaleTimeString("en-US", { hour12: false })}`;
});

const date = new Date();
document.getElementById("outDate").innerText = `${date.getFullYear()}-${pad2(
  date.getMonth() + 1
)}-${pad2(date.getDate())}`;
document.getElementById("inDate").innerText = `${date.getFullYear()}-${pad2(
  date.getMonth() + 1
)}-${pad2(date.getDate() + 1)}`;
